<template>
  <div class="cotnent">
    <div class="title">{{ $route.query.name }} - 导入到学校</div>
    <div class="tag">
      <a-icon
        type="exclamation-circle"
        theme="filled"
        style="margin-right: 8px; color: #2474ed"
      />本功能适用于将本次联考的参考学校成绩数据导入至学校中，以便于各校查看成绩统计结果。
    </div>
    <div class="title">统计参数</div>
    <a-radio-group
      v-model="type"
      style="margin-bottom: 24px"
      :disabled="progressNum < 100 && progressNum >= 0"
      name="radioGroup"
    >
      <a-radio :value="1"> 采用学校统计参数导入 </a-radio>
      <a-radio :value="2"> 采用联考统计参数导入 </a-radio>
    </a-radio-group>
    <div class="title">发布成绩到教师</div>
    <a-radio-group
      v-model="statusPublishTeacher"
      :disabled="progressNum < 100 && progressNum >= 0"
      name="radioGroup"
    >
      <a-radio :value="1"> 是 </a-radio>
      <a-radio :value="0"> 否 </a-radio>
    </a-radio-group>
    <div class="operate">
      <a-space>
        <div>选择学校</div>
        <a-button
          type="link"
          :disabled="
            schooList.length === 0 || btnLoading || emptySchool.length === 0
          "
          @click="allCheck"
          >全选</a-button
        >
        <a-button
          type="link"
          :disabled="
            schooList.length === 0 || btnLoading || emptySchool.length === 0
          "
          @click="cancleAll"
          >取消选择</a-button
        >
      </a-space>
    </div>
    <div class="checkbox">
      <a-checkbox-group
        v-if="schooList.length"
        v-model="checkList"
        :disabled="progressNum < 100 && progressNum >= 0"
        @change="checkboxChange"
      >
        <a-checkbox
          v-for="(item, index) in schooList"
          :key="index"
          :value="item.id"
          :disabled="item.isImport == 2"
          >{{ item.name }}
          <span v-if="item.isImport == 0">（未导入）</span>
          <span v-if="item.isImport == 2" style="color: #f56c6b"
            >（已导入）</span
          >
        </a-checkbox>
      </a-checkbox-group>
      <no-data v-else message="暂无参与统计学校"></no-data>
    </div>

    <a-button
      type="primary"
      :disabled="
        schooList.length === 0 || btnLoading || emptySchool.length === 0
      "
      @click="importToSchool"
      >{{ btnLoading ? "正在" : "" }}导入</a-button
    >
    <div v-if="![-1, 100].includes(progressNum)" class="progress">
      <a-progress
        stroke-linecap="square"
        stroke-color="#2474ED"
        :percent="progressNum"
      />
      <div v-if="progressNum < 100" class="text">正在导入</div>
      <div v-else class="text">数据导入完成。</div>
    </div>
  </div>
</template>

<script>
import { getstatschooloption } from "@/core/api/academic/common";
import { importExamToSchool } from "@/core/api/academic/unionSheet";
export default {
  name: "",
  data() {
    return {
      type: 2,
      checkList: [],
      schooList: [],
      btnLoading: false,
      progressNum: -1,
      schoolNameStrs: "",
      timer: null,
      isSearch: null,
      schoolIds: [],
      emptySchool: [],
      statusPublishTeacher: 0,
    };
  },
  created() {
    this.isSearch = 1;
    this.getSchoolist();
    this.getProgress();
  },
  destroyed() {
    this.clearTimer();
  },
  methods: {
    checkboxChange() {
      let schooList = this.schooList.filter((item) => item.isImport == 0);
      let schooListIds = schooList.map((item) => item.id);
      this.schoolIds = this.checkList.filter((item) =>
        schooListIds.includes(item)
      );
    },
    allCheck() {
      this.checkList = this.schooList.map((item) => item.id);
      this.checkboxChange();
    },
    cancleAll() {
      this.checkList = this.schooList
        .filter((item) => item.isImport == 2)
        .map((item) => item.id);
    },
    async getSchoolist() {
      const res = await getstatschooloption({
        id: this.$route.query.id,
        hasImport: 1,
      });
      this.schooList = res.data.data;
      this.emptySchool = res.data.data.filter((item) => item.isImport == 0);
      if (this.isSearch == 0) {
        this.checkList = this.schooList.map((item) => item.id);
      } else {
        this.checkList = this.schooList
          .filter((item) => item.isImport == 2)
          .map((item) => item.id);
      }
    },
    async importToSchool() {
      this.isSearch = 0;
      if (!this.checkList.length) {
        this.$message({
          type: "warning",
          message: "请选择学校",
          showClose: true,
        });
        return false;
      }
      this.btnLoading = true;
      try {
        const { id, examId } = this.$route.query;
        await importExamToSchool({
          statId: id,
          examId: examId,
          statParamType: this.type,
          isSearch: this.isSearch,
          statusPublishTeacher: this.statusPublishTeacher,
          schoolIds: this.schoolIds.join(","),
        });
        this.isSearch = 1;
        this.timer = setInterval(() => {
          this.getProgress();
        }, 1500);
      } catch {
        this.btnLoading = false;
      }
    },
    continueGet() {
      if (this.progressNum < 100 && this.progressNum > -1) {
        if (!this.timer) {
          this.timer = setInterval(() => {
            this.getProgress();
          }, 1500);
        }
      }
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.getSchoolist();
    },
    async getProgress() {
      try {
        const { id, examId } = this.$route.query;
        const res = await importExamToSchool({
          statId: id,
          examId: examId,
          statParamType: this.type,
          schoolIds: this.schoolIds.join(","),
          isSearch: this.isSearch,
        });
        this.progressNum = res.data.data;
        this.isSearch = 1;
        if (res.data.data >= 100) {
          this.btnLoading = false;
          this.clearTimer();
        } else {
          this.continueGet();
        }
      } catch {
        this.clearTimer();
        this.btnLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cotnent {
  width: 100%;
  height: 100%;

  .title {
    color: #0a1119;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 18px;
  }

  .tag {
    background: #f3f8ff;
    font-style: 14px;
    letter-spacing: 1px;
    color: #0a1119;
    box-sizing: border-box;
    padding: 8px 10px;
    width: fit-content;
    margin-bottom: 18px;
  }

  .operate {
    margin: 18px 0;
    font-weight: 600;
  }

  .checkbox {
    width: 50%;
    margin-bottom: 18px;
  }
}

.progress {
  width: 40%;
  margin: 18px 0;

  .text {
    color: #0a1119;
    font-size: 14px;
    margin-top: 8px;

    .ant-btn-link {
      padding: 0 !important;
      text-decoration: underline !important;
    }
  }
}
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
::v-deep .ant-checkbox-wrapper {
  margin-bottom: 6px;
  margin-right: 18px;
}
</style>
